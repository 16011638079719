import React from 'react';
import { css } from '@emotion/core';

import Layout from '../components/layout';
import SEO from '../components/seo';

import website from '../images/website.png';
import call_rep from '../images/step1_rep.jpg';

const contentWrapCSS = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-align: center;

  h1 {
    margin-top: 60px;
    font-size: 62px;
  }

  h2 {
    margin-bottom: 0;
    font-weight: 500;
    color: #517bfe;
    font-size: 24px;
  }

  a {
    text-decoration: none;
  }
`;

const imageChartCSS = css`
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 20px;

  @media only screen and (max-width: 480px) {
    display: block;

    .column {
      margin: auto;
      margin-bottom: 30px;
    }

    h4 {
      margin-bottom: 35px;
    }
  }

  .column {
    padding: 20px;
    width: 275px;

    img {
      width: 125px;
      margin-bottom: 15px;
    }

    h4 {
      color: #517bfe;
      margin-bottom: 5px;
    }
  }
`;

const ThankYouPage = () => (
  <Layout>
    <SEO title="Thank you" />
    <div css={contentWrapCSS}>
      <h1>Thank you!</h1>
      <p>
        One of our student loan specialists will get in touch with you to review
        your situation.
      </p>
      <h2>Have questions about student loans?</h2>
      <div css={imageChartCSS}>
        <div className="column">
          <a href="https://www.docupop.com">
            <img src={website} />
            <h4>Visit our website</h4>
          </a>
        </div>
        <h4 style={{ color: '#517bfe' }}>OR</h4>
        <div className="column">
          <img src={call_rep} style={{ width: 'calc(125px - 5%)' }} />
          <h4>Give us a call</h4>
          <a
            href="tel:+18888385871"
            className="text-gray-800 text-xl font-bold no-underline"
          >
            <span style={{ color: '#517bfe', fontWeight: 500, fontSize: 16 }}>
              (888) 838-5871
            </span>
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default ThankYouPage;
